import React from 'react';
import Navbar from "./components/navbar/Navbar";
import {ClerkProvider} from '@clerk/clerk-react'
import Hero from "./components/hero/Hero";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Footer from "./components/footer/Footer";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ImageLicense from "./pages/ImageLicense";
import Home from "./pages/Home";
import Search from "./pages/Search";
import ManageSubscriptions from "./pages/ManageSubscriptions";
import Generate from "./pages/Generate";
import ViewPhoto from "./pages/ViewPhoto";
import {CurrentUserProvider} from "./contexts/CurrentUserContext";
import settings from "./settings";



const router = createBrowserRouter([
  {path: "/", Component: Home},
  {path: '/photos/:search', Component: Search},
  {path: '/photo/:id', Component: ViewPhoto},
  {path: '/manage-subscription', Component: ManageSubscriptions},
  {path: '/generate', Component: Generate},
  {path: '/terms-and-conditions', Component: TermsAndConditions},
  {path: '/privacy-policy', Component: PrivacyPolicy},
  {path: '/images-license', Component: ImageLicense},
]);

function App() {
  return (
    <div>
      <ClerkProvider publishableKey={settings.clerkPublishableKey} afterSignOutUrl={'/'}>
        <CurrentUserProvider>
          <RouterProvider router={router}/>
        </CurrentUserProvider>
      </ClerkProvider>
    </div>
  );
}

export default App;
