import {Link} from "react-router-dom";

export default function Footer() {
  return <footer className="position-relative z-index-10 d-print-none">
    <div className="py-6 bg-gray-200 text-muted">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 mb-5 mb-lg-0">
            <div className="fw-bold text-uppercase text-dark mb-3">photozilla</div>
            <p>Stock images powered by AI.</p>
            <p>Photozilla is a product developed and owned by CSV HOLDING SRL, limited liability company from
              Romania 🇷🇴</p>
            <p>CUI: 43830350</p>
            <p>Address: Iași, Romania, str. Eugen Stătescu 26.</p>


          </div>
          <div className="col-lg-2 col-md-6 mb-5 mb-lg-0">
            <h6 className="text-uppercase text-dark mb-3">Categories</h6>
            <ul className="list-unstyled">
              <li><Link className="text-muted" to="/photos/nature">Nature</Link></li>
              <li><Link className="text-muted" to="/photos/people">People</Link></li>
              <li><Link className="text-muted" to="/photos/family">Family</Link></li>
              <li><Link className="text-muted" to="/photos/cars">Cars</Link></li>
              <li><Link className="text-muted" to="/photos/business">Business</Link></li>
            </ul>
          </div>
          <div className="col-lg-2 col-md-6 mb-5 mb-lg-0">
            <h6 className="text-uppercase text-dark mb-3">Company</h6>
            <ul className="list-unstyled">
              <li><Link className="text-muted" to="/terms-and-conditions">Terms and conditions </Link></li>
              <li><Link className="text-muted" to="/privacy-policy">Privacy policy</Link></li>
              <li><Link className="text-muted" to="/images-license">Image License</Link></li>
            </ul>
          </div>
          <div className="col-lg-4">
            <h6 className="text-uppercase text-dark mb-3">Contact</h6>
            <p className="mb-3"><a href="mailto:contact@photozilla.io">contact@photozilla.io</a></p>
          </div>
        </div>
      </div>
    </div>

    <div className="py-4 fw-light bg-gray-800 text-gray-300">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 text-center text-md-start">
            <p className="text-sm mb-md-0">© 2024, photozilla. All rights reserved.</p>
          </div>
          <div className="col-md-6">
            <ul className="list-inline mb-0 mt-2 mt-md-0 text-center text-md-end">
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
}