import Navbar from "../components/navbar/Navbar";
import Hero from "../components/hero/Hero";
import Footer from "../components/footer/Footer";
import React from "react";

export default function Generate() {
  return <div>
    <Navbar/>
    <Hero/>
    <h1>Generate</h1>
    <Footer/>
  </div>
}