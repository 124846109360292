import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";


export default function PrivacyPolicy() {
  return <div>
    <Navbar/>

    <div className="container">
      <div className="row">
        <div className="col-md-8 mx-auto mt-5 text-content">
          <h3>Privacy Policy</h3>
          <h5 className="text-muted">Last updated: 24th June 2024</h5>

          <p>
            CSV HOLDING SRL., and its affiliates, (collectively, "CSV HOLDING SRL") is committed to protecting
            your
            privacy. This privacy policy tells you about our collection, use, and disclosure of your personal
            data.
            The terms of this policy apply to all CSV HOLDING SRL websites and associated services that link to
            this
            privacy policy (the "websites"), it does not apply to websites or services that link to a different
            privacy notice.
          </p>

          <h3>Collection of Your Personal Information</h3>

          <p>
            The personal data we collect depends on how you interact with us, the websites you use, and the
            choices
            you make. We collect and process personal data about you with your consent and/or as necessary to
            provide the products you use, operate our business, meet our contractual and legal obligations,
            protect
            the security of our systems and our customers, or fulfil other legitimate interests.
          </p>

          <h3>Information you provide directly.</h3>

          <p>
            We collect personal information you provide to us. For example, we collect your name, email address,
            postal address, phone number, fax number, demographics, and other information you provide us. If you
            make a purchase, we also collect credit card numbers and other payment information.
          </p>

          <p>
            This information is collected and stored by 3rd parties that manage our different systems, which are.
          </p>
          <ul>
            <li>Plausible.io which manages our web analytics solution</li>
            <li>Clerk.com which manages our user authentication and authorization solution</li>
            <li>Paddle.com which manages our payment processing solution</li>
          </ul>

          <h3>Information collected automatically.</h3>

          <p>
            CSV HOLDING SRL collects certain kinds of information, such as the website that referred you to us,
            your IP
            address, browser type and language, and access times. We also may collect navigational information,
            including information about the pages you view, the links you click, and other actions taken in
            connection with the site. We also collect further information as discussed below under "Use of
            Cookiess and Other Technologies".
          </p>

          <h3>Third-party sources.</h3>

          <p>
            We also obtain information from third parties. We protect information obtained from third parties
            according to the practices described in this statement, plus any additional restrictions imposed by
            the
            source of the information.
          </p>


          <p>
            We use the term "devices" to refer collectively to computers and mobile devices, as well as the
            internet
            browsers and other applications on your devices that interact with the internet. CSV HOLDING SRL
            engages in
            cross device data collection which refers to the process of collecting data from devices that have
            been
            determined through statistical analysis to be linked or related. Our partners whom we employ to
            provide
            this service use cross device data to provide interest-based advertising services to our customers
            and
            to deliver tailored ads to users across multiple devices.
          </p>

          <h3>Use of Your Personal Data</h3>

          <p>
            CSV HOLDING SRL uses your personal data collected through our websites for purposes described in the
            privacy policy or otherwise disclosed to you. For example, we use your personal data to:
          </p>

          <p>
            provide and deliver services, including securing, troubleshooting, improving, and personalizing
            those
            products;
          </p>
          <p>
            operate and improve our websites and business, including our internal operations and security
            systems,
            understand you and your preferences to enhance your experience using our websites,
          </p>
          <p>
            process your transactions,
          </p>
          <p>
            provide customer service and respond to your questions,
          </p>
          <p>
            perform research and analysis aimed at improving our products, services and technologies,
            send you information, including confirmations, invoices, technical notices, updates, security
            alerts,
            and support and administrative messages,
            communicate with you about new products, offers, promotions, rewards, contests, upcoming events, and
            other about our products and those of our selected partners (see the Choice and Preferences section
            of
            this privacy statement for how to change your preferences for promotional communications), and
            display content, including advertising, that is customized to your interests and preferences.
            In carrying out these purposes, we combine data we collect from different sources to give you a more
            seamless, consistent, and personalized experience.
          </p>


          <h3>Location of Personal Data, Data Privacy Framework and Standard Contractual Clauses</h3>

          <p>
            Personal data collected by CSV HOLDING SRL may be stored and processed in your country or region, or
            in any
            other country where we or our affiliates, subsidiaries, or service providers maintain facilities.
            Currently we primarily use data centers in the United States. The storage locations are chosen in
            order
            to operate efficiently, to improve performance, and to create redundancies in order to protect the
            data
            in the event of an outage or other problem. We take steps designed to ensure that the data we
            collect
            under this Privacy Policy is processed according to the provisions of this Privacy Policy and
            applicable
            law wherever the data is located.
          </p>

          <p>
            We transfer personal data from the European Economic Area and Switzerland to other countries.
            When we do so, we use a variety of legal mechanisms, including contracts where required by
            applicable
            law, to help ensure your rights and protections. To learn more about the European Commission's
            decisions
            on the adequacy of personal data protections, please go here.
          </p>


          <h3>Sharing of Your Personal Data</h3>

          <p>
            Except as disclosed in this privacy policy, CSV HOLDING SRL does not share your personal data with
            any
            outside parties.
          </p>

          <p>
            We share personal data with your consent or as necessary to complete your transactions or provide
            the
            products or services you have requested or authorized. We may also share your personal data within
            our
            corporate group, including with our subsidiaries, affiliates, and divisions, all of whom may use
            your
            data for the purposes disclosed herein. In addition, we may share your personal data with our
            approved
            and appointed distributor network.
          </p>

          <p>
            We may share your personal data with service providers who perform services on our behalf. For
            example,
            we may hire other companies to handle the processing of payments, to provide data storage, to host
            websites, to fulfill orders and shipments, to assist in direct marketing, analyze your interests and
            preferences and to conduct audits, etc. Those companies will be permitted to obtain only the
            personal
            data they need to provide the service. They are required to maintain the confidentiality of the
            information and are prohibited from using it for any other purpose. CSV HOLDING SRL maintains
            contracts
            with these third parties requiring their treatment (access, use and disclosure) of personal data to
            be
            consistent with our obligations under the law.
          </p>

          <p>
            Information about our customers, including personal data, may be disclosed as part of any merger,
            transfer, divestiture, acquisition, or sale of all or a portion of the company and/or its assets, as
            well as in the unlikely event of insolvency, bankruptcy, or receivership, in which personal data
            would
            be transferred as one of the business assets of the company. We will notify you of such an
            occurrence as
            described in the "Changes to This Privacy Policy" section below.
          </p>

          <p>
            We reserve the right to disclose your personal data if we have a good faith understanding we are
            required to do so by law (including to meet national security or law enforcement requirements), or
            in
            the good-faith belief that such action is reasonably necessary to comply with legal process, respond
            to
            claims, or protect the rights, property or safety of our company, employees, customers, or the
            public.
          </p>

          <h3>Retention of Personal Data</h3>

          <p>
            We retain personal data for as long as necessary to provide the products and fulfill the
            transactions
            you have requested, or for other essential purposes such as complying with our legal obligations,
            resolving disputes, and enforcing our agreements. Because these needs can vary for different data
            types
            in the context of different products, actual retention periods can vary significantly based on
            criteria
            such as user expectations or consent, the sensitivity of the data, the availability of automated
            controls that enable users to delete data, and our legal or contractual obligations.
          </p>

          <h3>Social Media</h3>

          <p>
            On some pages, we allow you to share personal data with third parties, such as social networks like
            Facebook. In these instances, you are agreeing to the data being shared and the shared data is
            subject
            to the privacy policies of such third parties. We may combine information we receive from those
            platforms (e.g., profile information, likes, pageviews) with other information we collect from you
            or
            other sources and use and disclose it as described in this privacy policy. Except where required by
            applicable law or regulation, we do not control and do not assume any responsibility for the use of
            personal data by such third parties. For more information about the third party's purpose and scope
            of
            their use of personal data in connection with sharing features, please visit the privacy policies of
            these third parties.
          </p>

          <h3>Accessing Your Personal Data</h3>

          <p>
            If you would like to review or revise information that you previously provided to us, you may access
            your information in the "My Account" section of the website. Your Account Executive can also assist
            you
            in making any changes, as well as in exercising your right to have your personal data deleted. If
            you
            need additional assistance, please contact us at privacy@gettyimages.com. You can expect a response
            from
            us within 30 days. Please be advised that you cannot change your username without re-registering.
          </p>

          <h3>Use of Cookies and Other Technologies</h3>

          <p>
            CSV HOLDING SRL uses "cookies" and other technologies like pixel tags, web beacons and similar
            technologies
            ("Cookies") to enable you to sign in to our services and to help personalize and improve your online
            experience. A cookie is a small text file that is placed on your hard drive or device. Cookies
            contain
            information, including personal data that can later be read by a web server in the domain that
            issued
            the Cookie to you. The information that Cookies collect includes the date and time of your visit,
            your
            registration information, and your navigational and purchase history. Pixel tags, also called web
            beacons, are clear image (i.e., non-visible) files that may be placed on a website, in an email or
            newsletter, and request information from your device such as your browser type and may assist us in
            determining your engagement with the content of the website and emails we send, how many users have
            visited certain pages, or how users have otherwise engaged with our messages or newsletters. We may
            use
            web beacons to help deliver cookies.
          </p>

          <p>Cookies offer you many conveniences:</p>

          <p>We use cookies for a variety of legitimate purposes, such as:</p>

          <p>
            They allow us to identify registered users when they return to the site so that they can retrieve
            previous image search results, access Boards and shopping carts and view their previous invoices.
            Cookies save you time by eliminating the need to repeatedly enter the same information.
            Cookies enable us to see what actions you take on this site and other websites. This allows us to
            deliver personalized content to you and other users, including search results and interest-based
            targeted advertising on our site and other websites.
          </p>

          <p>
            Cookies help to protect your security by checking your login details and may help prevent fraud.
            Cookies remember your preferences when using the site.
          </p>
          <p>
            Cookies also identify particular customers in the event such customer would like assistance from a
            live,
            online customer service representative.
          </p>
          <p>
            We strive to make this site and our family of sites, user-friendly and relevant. Our goal is to
            deliver
            more personalized products and services to you.
          </p>

          <h3>Analytics</h3>

          <p>
            Our websites may include Cookies from third-party service providers. In some cases, that is because
            we
            have hired the third party to provide services on our behalf, such as site analytics. For this
            purpose,
            CSV HOLDING SRL works with various third-party partners like Plausible.io to see what
            actions you take.
          </p>

          <h3>Our third-party partners like Plausible.io, Clerk.com use Cookies on our site to:</h3>

          <p>
            Measure and analyze aggregate usage and volume statistical information from our users on our site.
            Information gathered may include, but is not limited to the following: activity of the user browser
            when
            the user is visiting the site, the site path of users, general information regarding the visitor's
            internet service provider and host, and the time users come to the site.
          </p>
          <p>
            Remember our users' previous search of products and services, and dynamically display more relevant
            content to them.
          </p>
          <p>
            Record users' behavior on our site, such as pages visited, emails clicked, forms completed and
            products
            and services purchased to create customized advertisements and communications that better meet your
            needs
          </p>.
          <p>
            Evaluate user traffic and behavior on the site to compile activity reports.
            Conduct research and diagnostics to improve our products and services.
          </p>
          <p>
            Identify users arriving at the site via a referral from an affiliate website or sponsored link.
          </p>

          <h3>Choice and Preference</h3>

          <p>
            CSV HOLDING SRL respects your right to make choices about the use and disclosure of your personal
            data. If
            at any time you decide that you do not want to receive marketing emails from us or change the
            settings
            in relation to your personal email preferences, you can take the following actions as set out below:
          </p>

          <p>
            Access, correction, and deletion: If you wish to request access to, or correction or deletion of,
            certain personal data about you that we hold, you can visit the applicable section of your account
            profile. For other personal data, you can contact us as described at the bottom of this privacy
            policy.
            However, to the extent permitted by applicable law, we reserve the right to charge a fee or decline
            requests that are unreasonable, excessive, or prohibited by law, could adversely affect the privacy
            or
            other rights of another person, or where we are unable to authenticate you as the person to whom the
            data relates.
          </p>
          <p>
            Marketing Emails: If you do not wish to receive marketing emails, you will have the option of opting
            out
            and/or unsubscribing from our emails and mailing lists by adjusting your marketing preferences.
            Please note, if you choose not to receive marketing emails, you
            may still continue to receive transactional or account emails (e.g., purchase confirmations and
            account balance statements).
          </p>
          <p>
            Cookies: You can accept or decline cookies. Most browsers automatically accept cookies, but you can
            modify your browser setting to decline cookies. Please be aware that the site is optimally designed
            to
            operate with cookies enabled in your internet browser. If you choose to decline cookies, you may not
            be
            able to sign in or use other interactive features of our sites and services that depend on cookies.
            This
            may negatively impact your experience of the site. If you would like to opt-out from the use of
            pixel
            gifs in our emails, please email us at privacy@gettyimages.com. To view Google Analytics' currently
            available opt-outs for the web, please go here. If you choose to delete cookies, settings and
            preferences controlled by those cookies, including advertising preferences, may be deleted and may
            need
            to be recreated.
          </p>
          <p>
            Third Party Advertising: If you would like to opt out of information collection by our third-party
            advertising partners or otherwise find out more information about Internet advertising companies,
            please
            visit http://www.networkadvertising.org/choices/ and http://www.aboutads.info/choices/. You can
            prevent
            the companies listed from showing you targeted ads by submitting opt-outs. Opting-out will only
            prevent
            targeted ads so you may continue to see generic (non-targeted ads) from these companies after you
            opt-out.
          </p>
          <p>
            Mobile Devices: Use your mobile device settings to stop or limit interest-based advertising on that
            device. For instructions for using the advertising choice mechanisms provided by the most popular
            mobile
            operating systems, visit the NAI's Mobile Choices page at:
            http://www.networkadvertising.org/mobile-choices.
          </p>
          <p>
            Do Not Track. Some browsers have incorporated "Do Not Track" (DNT) features that can send a signal
            to
            the websites you visit indicating you do not wish to be tracked. Because there is not a common
            understanding of how to interpret the DNT signal, our websites do not currently respond to browser
            DNT
            signals. Instead, you can use the range of other tools to control data collection and use, including
            the
            cookie controls and advertising controls described above.
          </p>

          <h3>Legal bases for processing</h3>

          <p>
            Depending on where you are located, we may collect and process information about you only where we
            have
            legal bases for doing so under applicable data privacy laws. The legal bases depend on the services
            you
            use and how you use them. This means where applicable, we collect and use your information only
            where:
          </p>

          <p>
            We need it to provide you the services, including to operate the services, provide customer support
            and
            personalized features and to protect the safety and security of the services;
          </p>
          <p>
            It satisfies a legitimate interest (which is not overridden by your data protection interests), such
            as
            for research and development, to market and promote the services and to protect our legal rights and
            interests;
          </p>
          <p>You give us consent to do so for a specific purpose; or</p>
          <p>We need to process your data to comply with a legal obligation.</p>

          <p>
            If you have consented to our use of information about you for a specific purpose, depending on where
            you
            are located, you may have the right to change your mind at any time, but this will not affect any
            processing that has already taken place. Where we are using your information because we or a third
            party
            have a legitimate interest to do so, you have the right to object to that use though, in some cases,
            this may mean no longer using the services.
          </p>

          <h3>European Data Protection Rights</h3>

          <p>
            If the processing of personal data about you is subject to European Union data protection law, you
            have
            certain rights with respect to that data:
          </p>

          <p>
            You can request access to, and rectification or erasure of, personal data;
          </p>
          <p>
            If any automated processing of personal data is based on your consent or a contract with you, you
            have a
            right to transfer or receive a copy of your personal data in a usable and portable format;
            If the processing of personal data is based on your consent, you can withdraw consent at any time
            for
            future processing;
          </p>
          <p>You can to object to, or obtain a restriction of, the processing of personal data under certain
            circumstances; and
          </p>
          <p>
            For residents of France, you can send us specific instructions regarding the use of your data after
            your death.
          </p>

          <p>To make such requests, contact us at contact@photozilla.io. When we are processing data on behalf of
            another party that is the "data controller," you should direct your request to that party. You also
            have
            the right to lodge a complaint with a supervisory authority, but we encourage you to first contact
            us
            with any questions or concerns. Please be aware that those rights set out above may not apply in
            some
            instances, for example where our right to processing your personal data may be necessary for
            exercising
            the right of freedom of expression (e.g., news gathering); or for the establishment, exercise or
            defense
            of legal claims.
          </p>

          <h3>Your California Privacy Rights</h3>

          <p>
            If you are a California resident and the processing of personal information about you is subject to
            the
            California Consumer Privacy Act ("CCPA"), you have certain rights with respect to that information.
            In
            particular, you have a right to request that companies provide you with the following information:
          </p>

          <p>(1) The categories and specific pieces of personal information we have collected about you.</p>
          <p>(2) The categories of sources from which we collect personal information.</p>
          <p>(3) The purposes for collecting, using, or selling personal information.</p>
          <p>(4) The categories of third parties with which we share personal information.</p>
          <p>(5) For companies that disclose personal information for a business purpose, the categories of
            personal
            information disclosed about you for a business purpose.</p>
          <p>(6) For companies that sell personal information, the categories of personal information sold about
            you
            and the categories of third parties to which the personal information was sold, by category or
            categories of personal information for each third party to which the personal information was
            sold.</p>

          <p>To make such requests, contact us at contact@photozilla.io. Note that we have provided the
            information
            described in (2)-(5) in this privacy statement. With respect to (6), note that we do not sell
            personal
            information as defined by the CCPA.</p>

          <p>You also have a right to request that we delete personal information under certain circumstances,
            subject to a number of exceptions. Finally, you have a right to not be discriminated against for
            exercising rights set out in the CCPA.</p>

          <h3>Security of Your Personal Data</h3>

          <p>
            The security of your personal data is important to us. We take appropriate and reasonable steps
            designed
            to help protect your personal data from unauthorized access, use, disclosure, alteration, and
            destruction. For instance, in some cases we encrypt that information in transit using secure socket
            layer (SSL) technology. No method of transmission over the internet, or method of electronic
            storage, is
            100% secure. Therefore, while we strive to protect your personal data, we cannot guarantee its
            absolute
            security.
          </p>

          <p>
            If a password is used to protect your account and personal data, it is your responsibility to keep
            your
            password confidential.
          </p>

          <h3>Links</h3>

          <p>
            We may link to websites, including those of our third-party content providers or distributor
            network,
            that have different privacy policies and practices from those disclosed here. We assume no
            responsibility for the policies or practices of such linked sites, and encourage you to become
            acquainted with them prior to use.
          </p>

          <h3>Changes to This Privacy Policy</h3>

          <p>
            We reserve the right to change the terms of this privacy policy at any time when necessary to
            reflect
            changes in our products, how we process personal data, or applicable law. When we make changes, we
            will
            revise the "last updated" date at the top of the policy. If there are material changes to this
            statement, we will provide notice or obtain consent regarding such changes as may be required by
            law. We encourage you to review this policy whenever you visit one of our sites.
          </p>


          <h3>Children's personal data</h3>

          <p>
            We do not knowingly permit children (under the age of 13 in the US and UK or 16, if you live in the
            EEA)
            to sign up for a Getty Images account. If we discover someone who is underage has signed up for a
            Getty
            Images account, we will take reasonable steps to promptly remove that person's personal information
            from
            our records. If you believe a person who is underage has signed up for a Getty Images account,
            please
            contact us at contact@photozilla.io
          </p>

          <h3>Contact Information</h3>

          <p>
            If you have a privacy concern, complaint, or question, you can contact CSV HOLDING SRL by emailing
            contact@photozilla.io.
          </p>

          <h3>For the EU</h3>

          <p>
            Our data protection representative for the European Economic Area and Switzerland is located at
            CSV HOLDING SRL headquarters. You can contact them by emailing contact@photozilla.io.
          </p>
        </div>
      </div>
    </div>

    <Footer/>
  </div>
}