import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import React from "react";
import {CurrentUserContextData, useCurrentUser} from "../contexts/CurrentUserContext";
import {RedirectToSignUp} from "@clerk/clerk-react";
import {PricingCardBasicPlan, PricingCardCompletePlan, PricingCardProPlan} from "../components/pricing/Pricing";
import {useNavigate, redirect} from "react-router-dom";


const PRICES = {
  basic: 'pri_01j0nqdzye4p98nqmjwp9qrw0s',
  pro: 'pri_01j4q256xygn9tb6fhrnmndzwm',
  complete: 'pri_01j4q267zjz5jfd0z0mz01yxsm',

}

function checkSubscriptionAndDoCallback(currentUser: CurrentUserContextData, planName: string, priceId: string) {
  if (!currentUser) {
    return;
  }
  window.location.href = currentUser.user_subscription_data.portal_url

  // if (currentUser?.subscription?.product === planName) {
  //   window.location.href = currentUser.subscription.cancel_url
  //   return
  // }
  // (window as any).Paddle.Checkout.open({
  //   items: [
  //     {priceId: priceId, quantity: 1}
  //   ],
  //   customer: {
  //     email: currentUser?.primary_email_address,
  //   },
  //   customData: {
  //     userId: currentUser?.id
  //   }
  // })
}

function getButtonText(currentUser: CurrentUserContextData, planName: string) {
  if (!currentUser) {
    return 'Error';
  }
  if (currentUser.subscription?.product === planName && currentUser.subscription.canceled_at) {
    return `Canceled at ${currentUser.subscription.canceled_at}`
  }
  if (currentUser.subscription?.product === planName && !currentUser.subscription.canceled_at) {
    return 'Cancel'
  }
  return 'Select plan'
}


export default function ManageSubscriptions() {
  const currentUser = useCurrentUser();

  if (!currentUser) {
    return null;
  }

  console.log({subscription: currentUser.subscription})

  return <div>
    <Navbar/>
    <section className="py-6 bg-gray-100">
      <div className="container">
        <div className="text-center pb-lg-4">
          <p className="subtitle text-secondary">Manage your subscription</p>
          <h2 className="mb-5">
            {currentUser.user_subscription_data.active_subscriptions.length > 0 ? 'You have an active subscription' : 'You have no active subscription'}
          </h2>
        </div>

        <p>
          Access your Billing portal page <a href={currentUser.user_subscription_data.portal_url}>by clicking here</a>
        </p>

        {/*<div dangerouslySetInnerHTML={{__html: currentUser.user_subscription_data.pricing_tables}}></div>*/}

        <div className="row">
          <div className="col-4">
            <PricingCardBasicPlan
              highlighted={currentUser.subscription?.product === 'basic-plan'}
              actionText={getButtonText(currentUser, 'basic-plan')}
              actionCallback={() => {
                checkSubscriptionAndDoCallback(currentUser, 'basic-plan', PRICES.basic)
              }}
            />
          </div>
          <div className="col-4">
            <PricingCardProPlan
              highlighted={currentUser.subscription?.product === 'pro-plan'}
              actionText={getButtonText(currentUser, 'pro-plan')}
              actionCallback={() => {
                checkSubscriptionAndDoCallback(currentUser, 'pro-plan', PRICES.pro)
              }}/>
          </div>
          <div className="col-4">
            <PricingCardCompletePlan
              highlighted={currentUser.subscription?.product === 'complete-plan'}
              actionText={getButtonText(currentUser, 'complete-plan')}
              actionCallback={() => {
                checkSubscriptionAndDoCallback(currentUser, 'complete-plan', PRICES.complete)
              }}/>
          </div>
        </div>
      </div>
    </section>

    <Footer/>
  </div>
}