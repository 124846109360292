import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";


export default function TermsAndConditions() {
  return <div>
    <Navbar/>

    <div className="container">
      <div className="row">
        <div className="col-md-8 mx-auto mt-5 text-content">
          <h3>Terms &amp; Conditions</h3>
          <h5 className="text-muted">Last updated: 24th June 2024</h5>

          <p>
            THIS AGREEMENT GOVERNS YOUR USE OF THE PHOTOZILLA WEBSITE. TO LEARN ABOUT USAGE RIGHTS FOR OUR
            ROYALTY-FREE CONTENT, PLEASE READ OUR CONTENT LICENSE AGREEMENT.
          </p>

          <p>This Web site is owned and operated by CSV HOLDING SRL. Access and use of this Web
            site, is provided by CSV HOLDING SRL to you on condition that you accept
            these
            Terms of Use, and by accessing or using this Web site, you agree to these Terms of Use. If you do
            not
            agree to accept and abide by these Terms of Use you should not access or use this Web site.
          </p>

          <p>
            CSV HOLDING SRL may revise and update these Terms of Use at anytime and without notice. You are
            cautioned to
            review the Terms of Use posted on the Web site periodically. Your continued access or use of this
            Web
            site after any such changes are posted will constitute your acceptance of these changes.
          </p>

          <h3>Use of the Site</h3>

          <p>
            This Web site and its contents including such information as software, photographs, illustrations,
            audio
            files, video files, animations, flash files, data files, code snippets and other material
            (collectively, "Content") are intended for customers of CSV HOLDING SRL. You may not use this Web
            site or the Content
            for
            any purpose not related to your business with CSV HOLDING SRL. You are specifically prohibited from:
            (a)
            downloading, copying, or re-transmitting any or all of the Web site or the Content without, or in
            violation of, a written license or agreement with CSV HOLDING SRL; (b) using any data mining, robots
            or
            similar data gathering or extraction methods; (c) manipulating or otherwise displaying the Web site
            or
            the Content by using framing or similar navigational technology; (d) registering, subscribing,
            unsubscribing, or attempting to register, subscribe, or unsubscribe any party for any CSV HOLDING
            SRL
            product or service if you are not expressly authorized by such party to do so; and (e) using the Web
            site or the Content other than for its intended purpose, as determined solely in CSV HOLDING SRL's
            discretion, including but not limited to, to defame, abuse, harass, stalk, threaten or otherwise
            violate
            the legal rights (such as rights of privacy) of others, and/or to publish, post, distribute or
            disseminate any defamatory, infringing, obscene, pornographic, sexual, indecent or unlawful material
            or
            information.
          </p>

          <p>

            You may not interfere with the security of, or otherwise abuse this Web site or any system
            resources,
            services or networks connected to or accessible through this Web site. You may only use this Web
            site
            for lawful purposes.
          </p>

          <p>
            CSV HOLDING SRL will not be responsible to pay you or any Third Parties fees in connection with
            billing
            or
            collecting any licensing fees from you. You are responsible for all applicable taxes and any
            applicable
            Third Party fees (including but not limited to credit card fees, foreign exchange fees and cross
            border fees.)
          </p>

          <h3>Registration Data and Account Security</h3>

          <p>

            In consideration of your use of the site, you agree to: (a) provide accurate, current and complete
            information about you as may be prompted by any registration forms on the Site ("Registration
            Data");
            (b) maintain and promptly update the Registration Data, and any other information you provide to
            CSV HOLDING SRL, to keep it accurate, current and complete; (c) maintain the security of your
            password
            and
            identification; (d) notify CSV HOLDING SRL immediately of any unauthorized use of your account or
            other
            breach of security; (e) accept all responsibility for any and all activities that occur under your
            account; and (f) accept all risks of unauthorized access to the Registration Data and any other
            information you provide to CSV HOLDING SRL.
          </p>

          <p>
            In connection with the search feature, in order to
            use such functionalities of the Website, you hereby provide CSV HOLDING SRL and its partners a
            non-exclusive,
            non-revocable, sublicensable, worldwide, royalty-free license to use any inputs (e.g., search terms,
            image generation prompts) you provide on the Website to use in order to provide such functionalities
            to
            you as well as for any other of CSV HOLDING SRL's business purposes.
          </p>

          <h3>Intellectual Property</h3>

          <p>

            All materials (including the organization and presentation of such material) on this
            Web site (the "Materials") are the property of CSV HOLDING SRL and its licensors and
            may
            be protected by intellectual property laws including laws relating to copyrights, trade-marks,
            trade-names, internet domain names, and other similar rights.
          </p>

          <p>
            Unless you have entered into a separate agreement with CSV HOLDING SRL, such as a Royalty Free
            License
            Agreement, any other use of these Materials without CSV HOLDING SRL's written permission is
            prohibited.
          </p>

          <p>
            The Materials may only be used and copied for your own, non-commercial, personal or educational
            purposes, provided that the Materials are not modified and that copyright and other intellectual
            property notices are not deleted. You may not create derivative works from or otherwise exploit the
            Materials in any way.
          </p>

          <p>
            We also appreciate any feedback or other suggestions about our services that you may submit to us,
            but you understand that we may use such feedback or suggestions without any obligation to compensate
            you for them.
          </p>

          <p>Trademarks</p>

          Photozilla, CSV HOLDING SRL, the Photozilla design and any
          other product or service name or slogan contained in the Site are trademarks of CSV HOLDING SRL and its
          suppliers or licensors, and may not be copied, imitated or used, in whole or in part, without the prior
          written permission of CSV HOLDING SRL or the applicable trademark holder. You may not use metatags or
          any
          other "hidden text" utilizing "Photozilla" or "CSV HOLDING SRL" or any other name, trademark
          or product or service name of
          Photozilla or CSV HOLDING SRL without our prior written permission. In addition, the look and feel of
          the Site, including
          all page headers, custom graphics, button icons and scripts, is the service mark, trademark and/or trade
          dress of CSV HOLDING SRL and may not be copied, imitated or used, in whole or in part, without our prior
          written permission. All other trademarks, registered trademarks, product names and company names or
          logos mentioned in the Site are the property of their respective owners. Reference to any products,
          services, processes or other information, by trade name, trademark, manufacturer, supplier or otherwise
          does not constitute or imply endorsement, sponsorship or recommendation thereof by us.

          <h3>Privacy</h3>

          <p>
            CSV HOLDING SRL is committed to respecting the privacy of the personal information of the
            individuals with
            whom we interact. We have developed a Website Privacy Commitment to describe our privacy policies
            and
            practices and how we collect, use and disclose the personal information of those individuals who
            visit
            our website. Please see our Website Privacy Commitment for further details.
          </p>

          <h3>Currency of Web site</h3>
          <p>
            CSV HOLDING SRL updates the information on this Web site regularly. However, CSV HOLDING SRL cannot
            guarantee or
            accept any responsibility or liability for the accuracy, currency or completeness of the information
            on
            this Web site. CSV HOLDING SRL may revise, supplement or delete information, services and/or the
            resources
            contained in the Web site and reserves the right to make such changes without prior notification to
            past, current or prospective visitors.
          </p>

          <h3>Linked Web sites</h3>

          <p>
            This Web site may provide links to third party Web sites for your convenience only. The inclusion of
            these links does not imply that CSV HOLDING SRL monitors or endorses these Web sites. CSV HOLDING
            SRL does
            not
            accept any responsibility for such Web sites. CSV HOLDING SRL shall not be responsible or liable,
            directly
            or indirectly, for any damage or loss, caused or alleged to be caused by or in connection with the
            use
            of or the reliance upon any information, content, goods or services available on or through any
            third
            party Web sites or linked resources.
          </p>

          <h3>Internet software or computer viruses</h3>

          <p>
            Due to technical difficulties with the Internet, Internet software or transmission problems could
            produce inaccurate or incomplete copies of information contained on this Web site. Computer viruses
            or
            other destructive programs may also be inadvertently downloaded from this Web site.
          </p>

          <p>
            CSV HOLDING SRL shall not be responsible or liable for any software, computer viruses or other
            destructive,
            harmful or disruptive files or programs that may infect or otherwise impact your use of your
            computer
            equipment or other property on account of your access to, use of, or browsing on this Web site or
            your
            downloading of any of the Materials from this Web site. CSV HOLDING SRL recommends that you install
            appropriate anti-virus or other protective software.
          </p>

          <h3>
            Web Site and Materials Provided "As Is"
          </h3>

          <p>
            THIS WEB SITE, INCLUDING WITHOUT LIMITATION ANY EDITING TOOLS OR, MUSIC, AND THE MATERIALS ARE
            PROVIDED
            "AS IS" WITHOUT REPRESENTATION, WARRANTY OR CONDITION OF ANY KIND, EITHER EXPRESS OR IMPLIED,
            INCLUDING,
            BUT NOT LIMITED TO THE IMPLIED REPRESENTATIONS, WARRANTIES OR CONDITIONS OF MERCHANTABILITY, OR
            FITNESS
            FOR A PARTICULAR PURPOSE. CSV HOLDING SRL DOES NOT REPRESENT OR WARRANT THAT THIS WEB SITE, ANY
            EDITING
            TOOLS, MUSIC OR THE MATERIALS WILL MEET YOUR REQUIREMENTS OR THAT THEIR USE WILL BE UNINTERRUPTED OR
            ERROR FREE.
          </p>

          <h3>Limitation of Liability</h3>

          <p>
            YOU ASSUME ALL RESPONSIBILITY AND RISK FOR USE OF THIS WEB SITE AND THE MATERIALS INCLUDING WITHOUT
            LIMITATION ANY OF THE INFORMATION OR TOOLS CONTAINED THEREIN.
          </p>

          <p>
            IN NO EVENT SHALL CSV HOLDING SRL OR ANY OF ITS DIRECTORS, OFFICERS, EMPLOYEES, SHAREHOLDERS,
            PARTNERS,
            LICENSORS OR AGENTS BE LIABLE FOR ANY INCIDENTAL, INDIRECT, PUNITIVE, EXEMPLARY, OR CONSEQUENTIAL
            DAMAGES WHATSOEVER (INCLUDING DAMAGES FOR LOSS OF PROFITS, INTERRUPTION, LOSS OF BUSINESS
            INFORMATION,
            OR ANY OTHER PECUNIARY LOSS) IN CONNECTION WITH ANY CLAIM, LOSS, DAMAGE, ACTION, SUIT OR OTHER
            PROCEEDING ARISING UNDER OR OUT OF THESE TERMS OF USE, INCLUDING WITHOUT LIMITATION YOUR USE OF,
            RELIANCE UPON, ACCESS TO, OR EXPLOITATION OF THIS WEB SITE, THE MATERIALS OR ANY PART THEREOF, OR
            ANY
            RIGHTS GRANTED TO YOU HEREUNDER, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES,
            WHETHER THE ACTION IS BASED ON CONTRACT, TORT (INCLUDING NEGLIGENCE), INFRINGEMENT OF INTELLECTUAL
            PROPERTY RIGHTS OR OTHERWISE.
          </p>

          <p>
            IN ANY EVENT, THE TOTAL MAXIMUM AGGREGATE LIABILITY UNDER THESE TERMS OF USE OR THE USE OR
            EXPLOITATION
            OF ANY OR ALL PART OF THIS WEB SITE OR THE MATERIALS IN ANY MANNER WHATSOEVER SHALL BE LIMITED TO
            TEN
            (10.00) ROMANIAN LEU (RON).
          </p>

          <p>
            SOME JURISDICTIONS DO NOT ALLOW FOR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR
            CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
          </p>

          <h3>Indemnification</h3>

          <p>
            You agree to indemnify and hold CSV HOLDING SRL harmless against all claims or liability asserted
            against
            CSV HOLDING SRL arising out of or in connection with any breach by you or anyone acting on your
            behalf
            of
            any of these Terms of Use.
          </p>

          <h3>Notice and Procedure for Making Claims of Infringement</h3>

          <p>
            CSV HOLDING SRL respects the copyright of others, and we ask our users to do the same. If you
            believe
            that your work has been copied in a way that constitutes copyright infringement, or your
            intellectual
            property rights have been otherwise violated, please provide CSV HOLDING with a digital written
            communication
            addressed to our President including substantially the following information:
          </p>

          <p>
            an electronic or physical signature of the person authorized to act on behalf of the owner of the
            copyright or other intellectual property interest;
          </p>
          <p>
            description of the copyrighted work or other intellectual property that you claim has been
            infringed;
          </p>
          <p>
            a description of where the material that you claim is infringing is located on the Web site;
            your address, telephone number, and email address;
          </p>
          <p>
            a statement by you that you have a good faith belief that the disputed use is not authorized by the
            copyright owner, its agent, or the law;
          </p>
          <p>
            a statement by you, made under penalty of perjury, that the above information in your notice is
            accurate
            and that you are the copyright or intellectual property owner or authorized to act on the copyright
            or intellectual property owner's behalf.
          </p>

          <p>
            Please address your digital letter to our CEO, using our electronic mail address
            contact@photozilla.io.
          </p>

          <h3>Email</h3>
          <p>
            Feel free to visit our contact us page. However, the Internet is not a fully secure medium and any
            communication may be lost, intercepted or altered. CSV HOLDING SRL is not liable for any damages
            related
            to
            communications to or from this Web site. You agree with respect to any information provided by you
            to us
            through this Web site or via e-mail that:
          </p>

          <p>CSV HOLDING SRL has no obligation concerning such information;</p>
          <p>the information is non-confidential;</p>
          <p>
            CSV HOLDING SRL may use, disclose, distribute or copy the information and may use any ideas,
            concepts or
            know-how contained in the information for any purpose without compensation; and
            the information is truthful and disclosure of the information does not violate the legal rights of
            others.</p>

          <h3>Applicable law</h3>
          <p>
            This Web site is controlled, operated and administered by CSV HOLDING SRL from within the County of
            Iasi, Romania. This Web site can be accessed from all counties and territories of Romania, as well
            as
            from other countries around the world. As each of these jurisdictions has laws that may differ from
            those of Iași, Romania, by accessing this Web site, you acknowledge and agree that all matters
            relating to access to, or use of this Web site shall be governed by the laws of Romania
            and the federal laws of Romania applicable therein (without reference to conflicts of laws
            principles).
          </p>

          <p>
            You also agree that any claims or disputes whatsoever arising hereunder shall be submitted to the
            exclusive jurisdiction and venue of the courts of the County of Iași and acknowledge that you
            do so voluntarily.
          </p>

          <h3>General</h3>

          <p>
            CSV HOLDING SRL's failure to insist upon or enforce strict performance of any provision of these
            Terms of Use shall not be construed as a waiver of any provision or right.
          </p>

          <p>
            If any provision or part thereof of these Terms of Use is wholly or partially unenforceable the
            parties
            or, in the event the parties are unable to agree, a court of competent jurisdiction, shall put in
            place
            thereof an enforceable provision or provisions, or part thereof, that as nearly as possible reflects
            the
            terms of the unenforceable provision or part thereof.
          </p>

          <p>
            The parties have requested that these Terms of Use and all documents relating thereto be drawn up in
            English.
          </p>

          <h3>Age and Responsibility</h3>

          <p>
            You represent and warrant that you are of sufficient legal age to use the site and to create binding
            legal obligations for any liability you may incur as a result of the use of the site. You agree to
            be
            responsible for all of your use of the site (as well as for use of your account name and password(s)
            by
            others, including without limitation, minors living with you). You agree to supervise all usage by
            minors of the site under your name or account.
          </p>

          <h3>Contact</h3>

          <p>If you have concerns relating to this web site or these Terms of Use, please contact us.</p>
        </div>
      </div>
    </div>

    <Footer/>
  </div>
}