import React, {ReactNode, useContext, useEffect, useState} from "react";
import {useAuth} from "@clerk/clerk-react";
import settings from "../settings";

export type CurrentUserContextData = {
  id: string
  primary_email_address: string,
  subscription: {
    id: string,
    status: 'active' | 'canceled',

    product: string,

    cancel_url: string,
    update_payment: string,

    first_billed_at: string,
    next_billed_at: string
    canceled_at: string
  } | null

  user_subscription_data: {
    active_subscriptions: string[],
    portal_url: string,
    pricing_tables: string
  }
} | null

const CurrentUserContext = React.createContext<CurrentUserContextData>(null)


export function CurrentUserProvider({children}: { children: ReactNode }) {
  const auth = useAuth()
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<CurrentUserContextData | null>(null);

  useEffect(() => {
    setLoading(true);
    if (!auth.userId) {
      setLoading(false);
      return;
    }
    auth.getToken().then(token => {
      if (!token) {
        setLoading(false);
        return;
      }
      fetch(`${settings.backendUrl}/api/v1/me`, {
        method: 'get',
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(resp => resp.json()).then(respBody => {
        setUser(respBody)
        setLoading(false)
      }).catch(err => {
        setLoading(false);
      })
    })
  }, [auth.userId])

  if (loading) {
    return <div>Loading...</div>

  } else {
    return <CurrentUserContext.Provider value={user}>
      {children}
    </CurrentUserContext.Provider>
  }
}

export function useCurrentUser() {
  return useContext(CurrentUserContext);
}

export function useCurrentSubscription(): 'basic-plan' | 'pro-plan' | 'complete-plan' | null {
  let product = useCurrentUser()?.subscription?.product
  if (!product) {
    return null
  } else if (product === 'basic-plan') {
    return 'basic-plan'
  } else if (product === 'pro-plan') {
    return 'pro-plan'
  } else if (product === 'complete-plan') {
    return 'complete-plan'
  }
  return null
}