import {Link} from "react-router-dom";
import naturePng from '../../img/nature.png';
import womanPng from '../../img/woman.png';
import familyPng from '../../img/family.png';
import businessPng from '../../img/business.png';
import foodPng from '../../img/food.png';
import interiorPng from '../../img/interior.png';


function Category({url, image, title, subtitle}: { url: string, image: string, title: string, subtitle: string }) {
  return <div className="col-2 h-auto px-2 swiper-slide-duplicate swiper-slide-duplicate-active"
              data-swiper-slide-index="1">
    <div className="card card-poster gradient-overlay hover-animate mb-4 mb-lg-0">
      <Link className="tile-link" to={url}></Link>
      <img className="bg-image" src={image} alt="Nature"/>
      <div className="card-body overlay-content">
        <h6 className="card-title text-shadow text-uppercase">{title}</h6>
        <p className="card-text text-sm">{subtitle}</p>
      </div>
    </div>
  </div>
}

export default function ExploreCategories() {
  return <div className="container mt-5">
    <div className="row mb-5">
      <div className="col-md-8">
        <p className="subtitle text-primary">Or explore our categories</p>
        <h2>Popular categories</h2>
      </div>

    </div>
    <div className="row">

      <Category title={'Nature'} subtitle={'Inspiring places'} url={'/photos/nature'} image={naturePng}/>
      <Category title={'People'} subtitle={'Express emotions'} url={'/photos/people'} image={womanPng}/>
      <Category title={'Family'} subtitle={'Grow together'} url={'/photos/family'} image={familyPng}/>
      <Category title={'Business'} subtitle={'Inspire trust'} url={'/photos/business'} image={businessPng}/>
      <Category title={'Food'} subtitle={'Delicious choices'} url={'/photos/food'} image={foodPng}/>
      <Category title={'Interior'} subtitle={'Be cozy'} url={'/photos/interior'} image={interiorPng}/>

    </div>
  </div>
}