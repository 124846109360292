import Navbar from "../components/navbar/Navbar";
import Hero from "../components/hero/Hero";
import Footer from "../components/footer/Footer";
import React from "react";
import ExploreCategories from "../components/expore-categories/ExploreCategories";
import Pricing from "../components/pricing/Pricing";

import img1 from '../img/belt_1.png'
import img2 from '../img/belt_2.png'
import img3 from '../img/belt_3.png'
import img4 from '../img/belt_4.png'
import img5 from '../img/belt_5.png'
import img6 from '../img/belt_6.png'
import img7 from '../img/belt_7.png'
import img8 from '../img/belt_8.png'
import img9 from '../img/belt_9.png'
import img10 from '../img/belt_10.png'
import img11 from '../img/belt_11.png'
import img12 from '../img/belt_12.png'
import {Link} from "react-router-dom";


function SmallExampleImage({url, image}: { url: string, image: string }) {
  return <div className="col-1 mx-0 px-0">
    <Link to={url}>
      <img className="w-100 hover-scale"
           src={image}
           style={{height: '10rem', objectFit: 'cover'}}/>
    </Link>
  </div>
}


export default function Home() {
  return <div>
    <Navbar/>
    <Hero/>
    <ExploreCategories/>
    <Pricing/>

    <div className="container-fluid px-0">
      <div className="row">

        <SmallExampleImage url={'/photo/53993002-picturesque-norwegian-fjord-in-autumn'} image={img1}/>
        <SmallExampleImage url={'/photo/53990002-tourists-taking-photos-near-the-eiffel-tower'} image={img2}/>
        <SmallExampleImage url={'/photo/53987002-profile-of-a-young-boxer'} image={img3}/>
        <SmallExampleImage url={'/photo/53982002-stormy-ocean-waves-near-a-coastal-cliff'} image={img4}/>
        <SmallExampleImage url={'/photo/53981002-crowd-surfing-at-a-rock-concert'} image={img5}/>
        <SmallExampleImage url={'/photo/53983002-cozy-living-room-with-colorful-decor'} image={img6}/>
        <SmallExampleImage url={'/photo/53999002-elegant-cocktail-with-orange-peel-garnish'} image={img7}/>
        <SmallExampleImage url={'/photo/51067000-adorable-piglet-peeking-over-wooden-fence'} image={img8}/>
        <SmallExampleImage url={'/photo/45456001-man-in-warehouse-with-barrels'} image={img9}/>
        <SmallExampleImage url={'/photo/45462001-mother-holding-baby'} image={img10}/>
        <SmallExampleImage url={'/photo/51054000-variety-of-colorful-smoothies'} image={img11}/>
        <SmallExampleImage url={'/photo/51049000-dramatic-cloudy-sky-over-open-landscape'} image={img12}/>

      </div>
    </div>
    <Footer/>
  </div>
}