import {useParams} from "react-router-dom";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import {useEffect, useState} from "react";
import {useAuth} from "@clerk/clerk-react";
import settings from "../settings";
import {useCurrentSubscription} from "../contexts/CurrentUserContext";
import {SearchResults} from "./Search";

type Image = {
  id: string
  display_url: string
  title: string,
  downloads: {
    small: {
      url: string,
      size: { height: number, width: number }
    },
    medium: {
      url: string,
      size: { height: number, width: number }
    },
    large: {
      url: string,
      size: { height: number, width: number }
    } | null,
    extra_large: {
      url: string,
      size: { height: number, width: number }
    } | null,
  }
}

function DownloadButton({name, spec}: {
  name: string,
  spec: { url: string, size: { height: number, width: number } }
}) {
  return <a href={spec.url} className={'mb-2'}>
    Download {name} ({spec.size.height}x{spec.size.width})
  </a>
}

function DownloadDisabled({name}: { name: string }) {
  return <span className="text-muted mb-2">
    Download {name} (not available)
  </span>
}

export default function ViewPhoto() {
  const {id} = useParams<{ id: string }>();
  const auth = useAuth()
  const parts = id?.split('-')
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Image | null>(null)
  const subscription = useCurrentSubscription();

  useEffect(() => {
    if (!parts) {
      return;
    }
    setIsLoading(true);
    auth.getToken().then(token => {
      fetch(`${settings.backendUrl}/api/v1/photo/${parts[0]}`, {
        method: 'get',
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(resp => resp.json()).then(data => {
        setIsLoading(false);
        setData(data)
      })
    })
  }, [id])

  return <div>
    <Navbar/>
    <div className="container my-3">
      {isLoading && !data && <div>Loading...</div>}
      {!isLoading && !data && <div>Failed to load the photo</div>}
      {!isLoading && data && <div className="row">
        <div className="col-12 d-flex justify-content-center">
          <div className="" style={{maxHeight: '80vh'}}>
            <img src={data?.display_url} alt="" className="img-fluid h-100" style={{objectFit: 'contain'}}/>
          </div>
          <div className="card border-0 shadow-sm" style={{maxWidth: '30rem'}}>
            <div className="card-body">
              <h4>{data.title}</h4>

              <div className="d-flex flex-column align-items-begin">
                {!subscription && <div className="col-lg-12 position-relative my-4">
                  <div>
                    <h6 className="text-uppercase">Do you like this image and want to use it without the watermark?</h6>
                    <p className="text-muted text-sm mb-5 mb-lg-0">
                      You can download this image in various sizes, watermark free, up to 4K once you buy a subscription
                    </p>
                    <p className="text-muted text-sm mb-5 mb-lg-0">
                      With a subscription you can access the full image library, download unlimited images, and even
                      generate new ones if you can't find what you need!
                    </p>
                  </div>
                </div>}

                {data.downloads.small ? <DownloadButton name={'Small'} spec={data.downloads.small}/> :
                  <DownloadDisabled name={'Small'}/>}
                {data.downloads.medium ? <DownloadButton name={'Medium'} spec={data.downloads.medium}/> :
                  <DownloadDisabled name={'Medium'}/>}
                {data.downloads.large ? <DownloadButton name={'Large'} spec={data.downloads.large}/> :
                  <DownloadDisabled name={'Large'}/>}
                {data.downloads.extra_large ? <DownloadButton name={'4K'} spec={data.downloads.extra_large}/> :
                  <DownloadDisabled name={'4K'}/>}
              </div>
            </div>
          </div>
        </div>
      </div>}

    </div>

    <div className="container">
      <h1>Similar photos</h1>

      {data && <SearchResults search={data.title} algorithm={'recommendations'}/>}
    </div>
    <Footer/>
  </div>
}