import {SignedIn, SignedOut, SignInButton, UserButton} from "@clerk/clerk-react";
import React from "react";
import {Link} from "react-router-dom";


type MegamenuColumn = {
  categories: {
    title: string,
    items: { name: string, url: string }[]
  }[]
}

function MegamenuDropdown({columns}: { columns: MegamenuColumn[] }) {
  return <div className="dropdown-menu megamenu py-lg-0" style={{marginTop: '-2rem'}}>
    <div className="row">
      <div className="col-lg-9">
        <div className="row p-3 pe-lg-0 ps-lg-5 pt-lg-5">
          {columns.map((column) => {
            return <div className="col-lg-3">

              {column.categories.map(category => {
                return <React.Fragment key={category.title}>
                  <h6 className="text-uppercase">{category.title}</h6>
                  <ul className="megamenu-list list-unstyled">
                    {category.items.map(item => {
                      return <li className="megamenu-list-item" key={item.url}>
                        <Link className="megamenu-list-link" to={item.url}>
                          {item.name}
                        </Link>
                      </li>
                    })}
                  </ul>
                </React.Fragment>
              })}
            </div>
          })}
        </div>
        <div className="col-lg-3 d-none d-lg-block position-relative">
          <img className="bg-image"
               src="https://s3.fr-par.scw.cloud/photozilla-static/img/happy_photographer.png" alt=""/>
        </div>
      </div>

      <div className="col-lg-3 d-none d-lg-block position-relative">
        <img className="bg-image" src="https://s3.fr-par.scw.cloud/photozilla-static/img/happy_photographer.png" alt=""
             title="Happy photographer with an urban background"/>
      </div>
    </div>
  </div>
}

const megamenuData: MegamenuColumn[] = [
  {
    categories: [
      {
        title: 'Nature',
        items: [
          {name: 'Fjords', url: '/photos/fjords'},
          {name: 'Ocean', url: '/photos/ocean'},
          {name: 'Beach', url: '/photos/beach'},
          {name: 'Mountains', url: '/photos/mountains'},
          {name: 'Forest', url: '/photos/forest'},
        ]
      },
      {
        title: 'Buildings',
        items: [
          {name: 'Cityscape', url: '/photos/cityscape'},
          {name: 'House', url: '/photos/house'},
          {name: 'Garage', url: '/photos/garage'},
        ]
      }
    ]
  },
  {
    categories: [
      {
        title: 'Sports',
        items: [
          {name: 'Soccer', url: '/photos/soccer'},
          {name: 'Basketball', url: '/photos/basketball'},
          {name: 'Baseball', url: '/photos/baseball'},
          {name: 'Chess', url: '/photos/chess'},
        ]
      },
    ]
  },
  {
    categories: [
      {
        title: 'People',
        items: [
          {name: 'Family', url: '/photos/family'},
          {name: 'Friends', url: '/photos/friends'},
          {name: 'Woman', url: '/photos/woman'},
          {name: 'Man', url: '/photos/man'},
          {name: 'Children', url: '/photos/children'},
          {name: 'Boy', url: '/photos/boy'},
          {name: 'Girl', url: '/photos/girl'},
        ]
      },
    ]
  },
  {
    categories: [
      {
        title: 'Food & Drinks',
        items: [
          {name: 'Fast food', url: '/photos/fast+food'},
          {name: 'Gourmet', url: '/photos/gourmet'},
          {name: 'Cocktails', url: '/photos/cocktails'},
          {name: 'Beer', url: '/photos/beer'},
          {name: 'Restaurant', url: '/photos/restaurant'},
        ]
      },
    ]
  }
]


export default function Navbar() {
  return <nav className="navbar navbar-expand-lg shadow navbar-light bg-white">
    <div className="container-fluid">
      <div className="d-flex align-items-center">
        <Link className="navbar-brand py-1" to="/">
          <img style={{height: '3rem'}}
               src="https://s3.fr-par.scw.cloud/photozilla-static/logo.png"
               alt="Directory logo"/>
        </Link>
      </div>
      <button className="navbar-toggler navbar-toggler-right" type="button" data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false"
              aria-label="Toggle navigation"><i className="fa fa-bars"></i></button>
      <div className="collapse navbar-collapse" id="navbarCollapse">
        <form className="form-inline mt-4 mb-2 d-sm-none" action="#" id="searchcollapsed">
          <div className="input-label-absolute input-label-absolute-left w-100">
            <label className="label-absolute" htmlFor="searchcollapsed_search"><i className="fa fa-search"></i><span
              className="sr-only">What are you looking for?</span></label>
            <input className="form-control form-control-sm border-0 shadow-0 bg-gray-200" id="searchcollapsed_search"
                   placeholder="Search" aria-label="Search" type="search"/>
          </div>
        </form>
        <ul className="navbar-nav ms-auto">
          <li className="nav-item">
            <Link className="nav-link" to="/">
              Home
            </Link>
          </li>
          <li className="nav-item dropdown position-static">
            <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">
              Categories
            </a>
            <MegamenuDropdown columns={megamenuData}/>
          </li>


          <SignedOut>
            <li className="nav-item">
              <Link className="nav-link" to="/#pricing">
                Pricing
              </Link>
            </li>
            <SignInButton>
              <li className="nav-item mt-3 mt-lg-0 ms-lg-3 d-lg-none d-xl-inline-block">
                <button className="btn btn-primary">Sign in</button>
              </li>
            </SignInButton>
          </SignedOut>
          <SignedIn>
            <li className="nav-item">
              <Link className="nav-link" to="/manage-subscription">Manage subscription</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/generate">Generate</Link>
            </li>
            <li className="nav-item mt-3 mt-lg-0 ms-lg-3 d-lg-none d-xl-inline-block">
              <UserButton/>
            </li>
          </SignedIn>

        </ul>
      </div>
    </div>
  </nav>
}