import {ReactNode} from "react";
import {useClerk} from "@clerk/clerk-react";
import {useNavigate} from "react-router-dom";

function PricingCard({children, highlighted = false, buttonText = 'Start now', buttonCallback}: {
  children: ReactNode,
  highlighted?: boolean,
  buttonText?: string,
  buttonCallback?: () => void
}) {
  const clerk = useClerk();

  return <div className={`card mb-5 mb-lg-0 border-0 shadow ${highlighted ? 'card-highlight' : ''}`}>
    <div className="card-body">
      {highlighted && <div className="card-status bg-primary"></div>}
      {children}
      <div className="text-center">
        <a className="btn btn-outline-primary"
           onClick={() => {
             if (buttonCallback) {
               buttonCallback();
               return
             }
             clerk.redirectToSignIn()
           }}>
          {buttonText}
        </a>
      </div>
    </div>
  </div>
}

function PricingTitle({children}: { children: ReactNode }) {
  return <h2 className="text-base subtitle text-center text-primary py-3">{children}</h2>
}

function PricingPrice({amount}: { amount: number }) {
  return <p className="text-muted text-center">
    <span className="h1 text-dark"> {amount}€</span>
    <span className="ms-2">/ month</span>
  </p>
}


function FeatureList({children}: { children: ReactNode }) {
  return <ul className="fa-ul my-4">
    {children}
  </ul>
}

function IncludedFeature({children}: { children: ReactNode }) {
  return <li className="mb-3">
    <span className="fa-li text-primary">
      <i className="fas fa-check"></i>
    </span>
    {children}
  </li>
}

function NotIncludedFeature({children}: { children: ReactNode }) {
  return <li className="mb-3 text-muted">
    <span className="fa-li">
      <i className="fas fa-times"></i>
    </span>
    {children}
  </li>
}


type PlanProps = {
  highlighted: boolean,
  actionText?: string
  actionCallback?: () => void
}


export function PricingCardBasicPlan({highlighted = false, actionCallback, actionText}: PlanProps) {
  return <PricingCard highlighted={highlighted} buttonText={actionText} buttonCallback={actionCallback}>
    <PricingTitle>Basic</PricingTitle>
    <PricingPrice amount={19}/>
    <hr/>
    <FeatureList>
      <IncludedFeature>Full library access</IncludedFeature>
      <IncludedFeature>50 generations per month</IncludedFeature>
      <IncludedFeature>Full HD images readily available</IncludedFeature>
      <NotIncludedFeature>Upscaling images up to 4k</NotIncludedFeature>
      <NotIncludedFeature>Private generations</NotIncludedFeature>
    </FeatureList>
  </PricingCard>
}

export function PricingCardProPlan({highlighted = false, actionCallback, actionText}: PlanProps) {
  return <PricingCard highlighted={highlighted} buttonText={actionText} buttonCallback={actionCallback}>
    <PricingTitle>Pro</PricingTitle>
    <PricingPrice amount={39}/>
    <hr/>
    <FeatureList>
      <IncludedFeature>Full library access</IncludedFeature>
      <IncludedFeature>125 generations per month</IncludedFeature>
      <IncludedFeature>Full HD images readily available</IncludedFeature>
      <IncludedFeature>Upscaling images up to 4k</IncludedFeature>
      <NotIncludedFeature>Private generations</NotIncludedFeature>
    </FeatureList>
  </PricingCard>
}

export function PricingCardCompletePlan({highlighted = false, actionCallback, actionText}: PlanProps) {
  return <PricingCard highlighted={highlighted} buttonText={actionText} buttonCallback={actionCallback}>
    <PricingTitle>Complete</PricingTitle>
    <PricingPrice amount={89}/>
    <hr/>
    <FeatureList>
      <IncludedFeature>Full library access</IncludedFeature>
      <IncludedFeature>300 generations per month</IncludedFeature>
      <IncludedFeature>Full HD images readily available</IncludedFeature>
      <IncludedFeature>Upscaling images up to 4k</IncludedFeature>
      <IncludedFeature>Private generations</IncludedFeature>
    </FeatureList>
  </PricingCard>
}

export default function Pricing() {
  return <div className="container my-5" id="pricing">

    <div className="row">
      <h1 className="text-center my-5">Simple pricing</h1>
      <div className="col-lg-4">
        <PricingCardBasicPlan highlighted={false}/>
      </div>
      <div className="col-lg-4">
        <PricingCardProPlan highlighted={true}/>
      </div>
      <div className="col-lg-4">
        <PricingCardCompletePlan highlighted={false}/>
      </div>
    </div>
  </div>
}