import Navbar from "../components/navbar/Navbar";
import Hero from "../components/hero/Hero";
import Footer from "../components/footer/Footer";
import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {useAuth} from "@clerk/clerk-react";
import settings from "../settings";

type Result = {
  id: string,
  url: string,
  thumbnail: string
  orientation: 'portrait' | 'landscape'
}


function PlaceholderImage() {
  return <div className="col-3 p-2 placeholder" style={{height: '20rem'}}></div>
}

export function SearchResults({search, algorithm = 'search'}: {
  search?: string,
  algorithm?: 'recommendations' | 'search'
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [results, setResults] = useState<Result[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const auth = useAuth()

  useEffect(() => {
    setLoading(true);
    setResults([]);

    auth.getToken().then(token => {
      fetch(`${settings.backendUrl}/api/v1/search?search=${search}&algorithm=${algorithm}&page=1`, {
        method: 'get',
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(result => {
        return result.json()
      }).then(resp => {
        setResults(resp.items)
        setLoading(false);
      })
    })

  }, [search])

  return <div className="container my-5">
    {loading && results.length === 0 && <div className="row placeholder-glow justify-content-between">
      <PlaceholderImage/>
      <PlaceholderImage/>
      <PlaceholderImage/>
      <PlaceholderImage/>
    </div>}

    {!loading && results.length === 0 && <div>
      <h1 className={'text-center text-muted'}>No results</h1>
      <h3 className={'text-center text-muted'}>Try another search such as</h3>
      <h3 className={'text-center text-muted'}>
        <Link to={'/photos/nature'}>Nature</Link>, <Link to={'/photos/business'}>Business</Link> or <Link
          to={'/photos/food'}>Food</Link>
      </h3>
    </div>}

    {results.length > 0 && <>
      <div className="row justify-content-around">

        {results.map(res => {
          if (res.orientation === 'portrait') {
            return <div className="col-3 mb-3" key={res.id}>
              <Link to={res.url}>
                <img className={'img-fluid hover-scale rounded shadow'} src={res.thumbnail} alt=""
                     style={{objectFit: 'cover', height: '100%', maxHeight: '20rem', width: '100%'}}/>
              </Link>
            </div>
          } else {
            return <div className="col-3 mb-3" key={res.id}>
              <Link to={res.url}>
                <img className={'img-fluid hover-scale rounded shadow'} src={res.thumbnail} alt=""
                     style={{objectFit: 'cover', height: '100%'}}/>
              </Link>
            </div>
          }
        })}

      </div>

      <div className={'d-flex'}>
        {!loading && <button className="mx-auto btn btn-primary" onClick={() => {
          setCurrentPage(currentPage + 1);

          setLoading(true);
          setResults([]);

          auth.getToken().then(token => {
            fetch(`${settings.backendUrl}/api/v1/search?search=${search}&page=${currentPage + 1}`, {
              method: 'get',
              headers: {
                Authorization: `Bearer ${token}`
              }
            }).then(result => {
              return result.json()
            }).then(resp => {
              setResults([...results, ...resp.items])
              setLoading(false);
            })
          })
        }}>
          Load more
        </button>}

        {loading && <button className="mx-auto btn btn-primary" disabled>Loading...</button>}
      </div>
    </>}


  </div>

}


export default function Search() {
  const {search} = useParams<{ search: string }>();

  return <div>
    <Navbar/>
    <Hero/>
    <SearchResults search={search}/>
    <Footer/>
  </div>
}