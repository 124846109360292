import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";


export default function Hero() {
  const params = useParams<{ search?: string }>();
  const [search, setSearch] = useState<string>(params.search || '');
  const navigate = useNavigate();

  useEffect(() => {
    setSearch(params.search || '')
  }, [params])


  return <section className="hero-home" style={{
    backgroundImage: `url(https://s3.fr-par.scw.cloud/photozilla-static/img/default_hero_bg.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  }}>
    <div className="container py-6 py-md-7 text-white z-index-20">
      <div className="row">
        <div className="col-xl-10">
          <div className="text-center text-lg-start">
            <p className="subtitle letter-spacing-4 mb-2 text-light text-shadow">
              Add visuals to your story
            </p>
            <h1 className="display-3 fw-bold text-shadow">
              Search for photos
            </h1>
          </div>
          <div className="search-bar mt-5 p-3 p-lg-1 ps-lg-4">
            <form onSubmit={(e) => {
              e.preventDefault();
              navigate(`/photos/${search}`)}
            }>
              <div className="row">
                <div className="col-lg-10 d-flex align-items-center form-group">
                  <input className="form-control border-0 shadow-0" type="text" name="search"
                         value={search}
                         onChange={e => setSearch(e.target.value)}
                         placeholder="What are you searching for?"/>
                </div>
                <div className="col-lg-2 d-grid">
                  <button className="btn btn-primary rounded-pill h-100" type="submit">
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
}