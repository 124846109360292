import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";


export default function ImageLicense() {
  return <div>
    <Navbar/>

    <div className="container">
      <div className="row">
        <div className="col-md-8 mx-auto mt-5 text-content">
          <h3>License agreement</h3>
          <h5 className="text-muted">Last updated: 24th June 2024</h5>

          <p>
            This is a license agreement between you and CSV HOLDING SRL that explains how you can use photos,
            illustrations, vectors, video clips and Music that you license from Photozilla, a product developed
            and owned by CSV HOLDING SRL. This includes content
            that is generated by you using our platform. By downloading content from Photozilla/CSV HOLDING SRL,
            you accept the terms of this agreement.
          </p>

          <h3>What types of licenses does Photozilla/CSV HOLDING SRL offer?</h3>
          <p>
            Photozilla/CSV HOLDING SRL offers a single type of license, which is named <b>standard licensing</b>.
          </p>
          <p>Every file downloaded from Photozilla comes with a standard license</p>
          <p>You can license files from Photozilla with a subscription</p>
          <p>You are welcome to use watermarked content from the Photozilla.io site on a complimentary basis for
            test or
            sample layout (composite) use only. Watermarked content cannot be used in any final materials or any
            publicly available materials and may only be used for the 30 days following download.</p>

          <h3>How can I use licensed content?</h3>
          <p>
            You may use content in any way that is not restricted (See Restricted Uses below). Subject to those
            restrictions and the rest of the terms of this agreement, the rights granted to you by CSV HOLDING
            SRL
            are:
          </p>
          <ul>
            <li>Perpetual - meaning there is no expiration or end date on your rights to use the content
              downloaded
              during your agreement.
            </li>
            <li>Non-exclusive, meaning that you do not have exclusive rights to use the content. CSV HOLDING SRL
              can
              license the same content to other customers.
            </li>
            <li>Worldwide, meaning content can be used in any geographic territory.</li>
            <li>Unlimited, meaning you can use the content in an unlimited number of projects and in any
              media.
            </li>
          </ul>
          <p>For purposes of this agreement, "use" means to copy, reproduce, modify, edit, synchronize, perform,
            display, broadcast, publish, or otherwise make use of.</p>

          <p>Examples of how you can use licensed content include: websites; blog posts; social media;
            advertisements;
            marketing campaigns; corporate presentations; newspapers; magazines; books; film and television
            productions; web and mobile applications; product packaging.</p>

          <h3>Restricted Uses.</h3>
          <ul>
            <li>No Unlawful Use. You may not use content in a pornographic, defamatory or other unlawful manner,
              to
              promote violence or hatred, or in violation of any applicable regulations or industry codes.
            </li>
            <li>
              No Standalone File Use. You may not use content in any way that allows others to download,
              extract,
              or redistribute content as a standalone file (meaning just the content file itself, separate
              from
              the project or end use).
            </li>
            <li>
              No Use in Trademark or Logo. Unless you purchase a custom license (not available for Music), you
              may
              not use content (in whole or in part) as the distinctive or distinguishing feature of a
              trademark,
              design mark, tradename, business name, service mark or logo. Additionally, you shall not be
              entitled
              to register (in any jurisdiction) such content (in whole or in part) as a trademark or rely on
              any
              such registrations, prior use, and/or accrued goodwill to prevent any third party use of the
              content
              or any similar content (including by us, our customers, or the copyright owner of such content).
            </li>
            <li>
              Sensitive Use Disclaimer Required. If you use content that features models or property in
              connection
              with a subject that would be unflattering or unduly controversial to a reasonable person (for
              example, sexually transmitted diseases), you must indicate: (1) that the content is being used
              for
              illustrative purposes only, and (2) any person depicted in the content is not real and was
              generated
              by AI.
              For example, you
              could say: "Stock photo. Generated by AI." No disclaimer is required for "editorial use only"
              content
              that is used in an editorial manner.
            </li>
            <li>
              No False Representation of Authorship. You may not falsely represent that you are the original
              creator of an end use that is made up largely of licensed content. For instance, you cannot
              create a
              painting based solely on licensed content and claim that you are the author.
            </li>
            <li>
              No claiming of being real. You may not claim that the content downloaded from Photozilla.io is
              real, and when needed, you need to disclose that the content is "generated by AI".
            </li>
            <li>
              No political campaigns. Music may not be used for political campaigns.
            </li>
            <li>
              No Use in Trademark or Logo. You may not incorporate any Music into a logo, trademark or service
              mark.
            </li>
            <li>
              No Products for Resale. You may not use content in
              connection with any goods or services intended for resale or distribution where the primary
              value
              lies in the content itself including, without limitation, cards, stationery items, paper
              products,
              calendars, apparel items, posters (printed on paper, canvas, or any other media), DVDs, mobile
              applications, NFTs or other items for resale, license or other distribution for profit. This
              includes "on demand" products (meaning products in which content is selected by a third party
              for
              customization on such product on a made-to-order basis), including, without limitation,
              postcards,
              mugs, t-shirts, posters and other items (this includes the sale of products through custom
              designed
              websites, as well as sites such as zazzle.com and cafepress.com).
            </li>
          </ul>

          <h3>Who, besides me, can use the licensed content?</h3>
          <p>The rights granted to you are non-transferable and non-sublicensable, meaning that you cannot transfer
            or
            sublicense them to anyone else. There are two exceptions:</p>

          <p>Employer or client. If you are purchasing on behalf of your employer or client, then your employer or
            client can use the content. In that case, you represent and warrant that you have full legal
            authority
            to
            bind your employer or client to the terms of this agreement. If you do not have that authority, then
            your
            employer or client may not use the content.
          </p>
          <p>Subcontractors. You may allow subcontractors (for example, your printer or mailing house) or
            distributors
            to use content in any production or distribution process related to your final project or end use.
            These
            subcontractors and distributors may not use the content for any other purpose.</p>

          <h3>Are there any seat/user license restrictions?</h3>

          <p>If you licensed the content with a subscription, this means that only one person (the same person)
            may
            access, license and use the content.</p>

          <p>If you need more than a single user to access the content:</p>

          <p>If you licensed the content with a subscription and need a multi-user plan for your team or business,
            please contact us.
          </p>

          <h3>User Accounts.</h3>

          <p>You will be responsible for tracking all activity for each user account, and you agree to: (1)
            maintain
            the security of all passwords and usernames; (2) notify CSV HOLDING SRL/Photozilla immediately of
            any
            unauthorized use or
            other breach of security; and (3) accept all responsibility for activity that occurs under each user
            account. CSV HOLDING SRL reserves the right to monitor downloads and user activity to ensure
            compliance
            with the
            terms of this agreement. If CSV HOLDING SRL determines that you are in breach of this or any other
            term
            of this
            agreement, it may suspend access to your account and seek further legal remedies.
          </p>

          <h3>Intellectual property rights.</h3>
          <p>Who owns the content?</p>
          <p>The content being AI generated, there is no owner of the content. So CSV HOLDING SRL does not own the
            intellectual property of the images, and nor does the client that downloads them through a paid
            subscription</p>

          <h3>Attribution</h3>
          <p>Do I need to include a photo credit? You do not need to include a photo credit for commercial use</p>
          <p>For content generated AI, the Artist Member's Name included should be "Photozilla.io" on platforms
            where
            an author is needed for the distribution of the images
          </p>

          <h3>Termination/Cancellation/Withdrawal.</h3>
          <p>Termination: Except in the case of subscriptions, this agreement is effective until it is terminated
            by
            either party. You can terminate this agreement by ceasing use of the content and deleting or
            destroying
            any copies. You must also sign-in to your account at least once per year to avoid account deletion.
            CSV HOLDING SRL may terminate this agreement at any time if you fail to comply with any of the
            terms, in
            which
            case you must immediately: cease using the content; delete or destroy any copies; and, if requested,
            confirm to CSV HOLDING SRL in writing that you have complied with these requirements.</p>

          <p>Subscription Termination. Unless renewed, subscription agreements terminate automatically at the end
            of
            the subscription period. CSV HOLDING SRL may terminate any licenses before the end of the
            subscription
            period if
            it reasonably believes there is a violation of this agreement and/or abuse of the subscription
            account,
            in which case you must immediately: cease using the content; delete or destroy any copies; confirm
            to
            CSV HOLDING SRL in writing that you have complied with these requirements; and pay CSV HOLDING SRL
            any amounts
            which
            remain owing to the end of the term of your subscription as set on your invoice.</p>

          <p>Social Media Termination. If you use the content on a social media platform or other third party
            website
            and the platform or website uses (or announces that it plans to use) the content for its own purpose
            or
            in a way that is contrary to this agreement, this agreement shall immediately terminate.</p>

          <h3>Refunds</h3>
          <p>
            <strong>Monthly Subscription Refunds</strong> - If you have signed up for a monthly subscription and
            your subscription is
            set to auto-renew, you will be eligible for a refund only after the first month if you cancel your
            subscription after the renewal of the 2nd month. Afterwards, you
            will not be eligible for refunds for previously auto-renewed monthly subscriptions.
          </p>


          <h3>Cancellations</h3>

          <p>
            Subscription Cancellations - If you have signed up for an annual subscription or subscription to the
            CSV HOLDING SRL/Photozilla, you may not cancel the subscription before the end of the subscription,
            unless (i) local
            law
            requires us to give you the option to cancel; or (ii) you are located in the United Kingdom or EU
            and
            you
            decide to cancel within the 14 day withdrawal (cooling-off) period of your original purchase and
            have
            not
            downloaded or generated, as applicable, any content against the subscription during
            this
            period. To determine if local law requires you are entitled to such early cancellation, please make
            a
            request for cancellation in writing through our contact us email form. If you are not entitled to
            early
            cancellation and your subscription is set to auto-renew, you can simply turn it off at any time in
            your
            account settings to ensure your subscription does not roll over for another term at the end of the
            subscription.
          </p>

          <p>Content Withdrawal.
            CSV HOLDING SRL may discontinue licensing any item of content at any time in its sole discretion.
            Upon
            notice
            from CSV HOLDING SRL, or upon your knowledge, that any content may be subject to a claim of
            infringement
            of a
            third party's right for which CSV HOLDING SRL may be liable, CSV HOLDING SRL may require you to
            immediately, and at your
            own expense: cease using the content, delete or destroy any copies; and ensure that your clients,
            distributors and/or employer do likewise. CSV HOLDING SRL will provide you with replacement content
            (determined
            by CSV HOLDING SRL in its reasonable commercial judgment) free of charge, subject to the other terms
            of
            this
            agreement.</p>

          <h3>Representations and Warranties.</h3>
          <p>CSV HOLDING SRL makes the following representations and warranties:</p>

          <p>Warranty of Non-Infringement. Except with respect to content identified as "editorial use only," your
            use
            of the content in accordance with this agreement and in the form delivered by CSV HOLDING SRL will
            not
            infringe
            on any copyright, moral right, trademark or other intellectual property right and will not violate
            any
            right of privacy or right of publicity; and all necessary model and/or property releases for use of
            the
            content in the manner authorized by this agreement have been obtained. Note that you are solely
            responsible for any edits made to the content</p>

          <p>"Editorial Use Only" Warranty Disclaimer. For content identified as "editorial use only," CSV HOLDING
            SRL
            warrants
            that the content will not infringe on any copyright or moral right of the artist, but it does not
            grant
            any right or make any warranty with regard to the use of names, people, trademarks, trade dress,
            logos,
            registered, unregistered or copyrighted designs, works of art or architecture depicted or contained
            in
            the content. In such cases, you are solely responsible for determining whether release(s) is/are
            required in connection with your proposed use of the content identified as "editorial use only," and
            you
            are solely responsible for obtaining such release(s). You acknowledge that no releases are generally
            obtained for content identified as "editorial use only," and that some jurisdictions provide legal
            protection against a person's image, likeness or property being used for commercial purposes when
            they
            have not provided a release.</p>

          <p>Caption/Metadata Disclaimer. While we have made reasonable efforts to correctly categorize, keyword,
            caption and title the content, CSV HOLDING SRL does not warrant the accuracy of such information, or
            of
            any
            metadata provided with the content.</p>

          <p>No Other Warranties: Cultural Assets – No Warranty. In some jurisdictions, the use of content
            depicting
            artwork, architecture or landscapes of cultural importance may be subject to additional
            authorization by
            and fees paid to the appropriate authorities. You will be solely responsible for obtaining such
            authorizations and remitting such fees. CSV HOLDING SRL disclaims all liability arising from the
            failure
            to do
            so.</p>

          <p>Except as provided in the "warranty of non-infringement" section above, the content is provided "as
            is"
            without representation, warranty or condition of any kind, either express or implied, including, but
            not
            limited to, implied representations, warranties or conditions of merchantability, or fitness for a
            particular purpose. CSV HOLDING SRL does not represent or warrant that the content will meet your
            requirements or
            that its use will be uninterrupted or error free.</p>

          <h3>Indemnification/Limitation of Liability.</h3>

          <p>Indemnification of CSV HOLDING SRL by you. You agree to defend, indemnify and hold harmless CSV
            HOLDING
            SRL and its parent,
            subsidiaries, affiliates, and content suppliers, and each of their respective officers, directors
            and
            employees from all damages, liabilities and expenses (including reasonable legal costs including
            attorney fees) arising out of or in connection with any breach or alleged breach by you (or anyone
            acting on your behalf) of any of the terms of this agreement.</p>

          <p>Indemnification of you by CSV HOLDING SRL. Provided that the content is only used in accordance with
            this
            agreement and you are not otherwise in breach of this agreement, and as your sole and exclusive
            remedy
            for any breach of the warranties set forth in Section 9(a) above, CSV HOLDING SRL agrees, subject to
            the
            terms of
            this Section 10, to defend, indemnify and hold harmless you, your corporate parent, subsidiaries and
            affiliates, and each of your respective officers, directors and employees from all damages,
            liabilities
            and expenses (including reasonable legal costs including attorney fees) arising out of or in
            connection
            with any breach or alleged breach by CSV HOLDING SRL of its warranty in Section 9(a) above. This
            indemnification
            does not apply to the extent any damages, costs or losses arise out of or are a result of
            modifications
            made by you to the content or the context in which the content is used by you. This indemnification
            also
            does not apply to your continued use of content following notice from CSV HOLDING SRL, or upon your
            knowledge,
            that the content is subject to a claim of infringement of a third party's right.</p>

          <p>Limitation of Liability. CSV HOLDING SRL AND ITS LICENSORS WILL NOT BE LIABLE TO YOU OR ANY OTHER
            PERSON
            OR ENTITY
            FOR ANY PUNITIVE, SPECIAL, INDIRECT, CONSEQUENTIAL, INCIDENTAL OR OTHER SIMILAR DAMAGES, COSTS OR
            LOSSES
            ARISING OUT OF THIS AGREEMENT, EVEN IF CSV HOLDING SRL OR ITS LICENSORS HAVE BEEN ADVISED OF THE
            POSSIBILITY OF
            SUCH DAMAGES, COSTS OR LOSSES. SOME JURISDICTIONS DO NOT PERMIT THE EXCLUSION OR LIMITATION OF
            IMPLIED
            WARRANTIES OR LIABILITY.</p>

          <h3>General Provisions.</h3>
          <p>Assignment. This agreement is personal to you and is not assignable by you without CSV HOLDING SRL's
            prior written
            consent. CSV HOLDING SRL may assign this agreement, without notice or consent, to any corporate
            affiliate or to
            any successor in interest, provided that such entity agrees to be bound by these terms.</p>

          <p>Audit/Certificate of Compliance. Upon reasonable notice, you agree to provide to CSV HOLDING SRL
            sample
            copies of
            projects or end uses that contain licensed content, including by providing CSV HOLDING SRL with free
            of
            charge
            access to any pay-walled or otherwise restricted access website or platform where content is
            reproduced.
            In addition, upon reasonable notice, CSV HOLDING SRL may, at its discretion, either through its own
            employees or
            through a third party, audit your records directly related to this agreement and your use of
            licensed
            content in order to verify compliance with the terms of this agreement. If any audit reveals an
            underpayment by you to CSV HOLDING SRL of five percent (5%) or more of the amount you should have
            paid,
            then in
            addition to paying CSV HOLDING SRL the amount of the underpayment, you also agree to reimburse CSV
            HOLDING SRL for the
            costs of conducting the audit. Where CSV HOLDING SRL reasonably believes that content is being used
            outside of
            the scope of the license granted under this agreement, you agree, at CSV HOLDING SRL's request, to
            provide a
            certificate of compliance signed by an officer of your company, in a form to be approved by CSV
            HOLDING
            SRL.</p>

          <p>Electronic storage. You agree to retain the name of Photozilla, the content's
            identification number and any other information that may be embedded in the electronic file
            containing
            the original content, and to maintain appropriate security to protect the content from unauthorized
            use
            by third parties. You may make as many copies of the content for back-up purposes.</p>

          <p>Governing Law/Arbitration. This agreement will be governed by the laws of Romania.</p>

          <p>Severability. If one or more of the provisions in this agreement is found to be invalid, illegal or
            unenforceable in any respect, the validity, legality and enforceability of the remaining provisions
            should not be affected. Such provisions should be revised only to the extent necessary to make them
            enforceable.</p>

          <p>Waiver. No action of either party, other than express written waiver, may be construed as a waiver of
            any
            provision of this agreement.</p>

          <p>Entire Agreement. No terms of conditions of this agreement may be added or deleted unless made in
            writing
            and accepted in writing by both parties, or issued electronically by CSV HOLDING SRL and accepted in
            writing by
            you.
            In the event of any inconsistency between the terms of this agreement and the terms contained on any
            purchase order sent by you, the terms of this agreement will apply.</p>

          <p>Notice. All notices required to be sent to CSV HOLDING SRL under this agreement should be sent via
            email
            to
            contact@photozilla.io. All notices to you will be sent via email to the email set out in your
            account.</p>

          <p>Taxes. You agree to pay and be responsible for any and all sales taxes, use taxes, value added taxes
            and
            duties imposed by any jurisdiction as a result of the license granted to you, or of your use of the
            licensed content.</p>

          <p>Interest on Overdue Invoices. If you fail to pay an invoice in full within the time specified, CSV
            HOLDING SRL may
            add a service charge of 1.5% per month, or such lesser amount as is allowed by law, on any unpaid
            balance
            until payment is received.</p>

          <p>Fees and Renewal. If your subscription is set to AUTOMATICALLY RENEW, you authorize CSV HOLDING SRL
            to
            charge the
            applicable subscription fees at the then applicable rate and taxes for the subscription to your
            credit
            card
            on file at the expiration of the term. You may change your auto-renewal preferences in your CSV
            HOLDING
            SRL
            account.
            Your subscription may only be cancelled as set out in Section 8(c). CSV HOLDING SRL may deactivate
            your
            subscription
            without prior notice if CSV HOLDING SRL is unable to process payment through the credit card
            provided by
            you.</p>

          <p>Licensing Entity. The licensing entity under this agreement is CSV HOLDING SRL, a limited liability
            company based in Romania.</p>
        </div>
      </div>
    </div>

    <Footer/>
  </div>
}